import React, { useState } from "react";
import { useEffect } from "react";
import { KeyboardAvoidingView, TextInput, Linking } from "react-native"; // Added Linking import
import { Platform } from "react-native";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  BackHandler,
  Alert,
} from "react-native";
import AuthButton from "../../../customComponents/Button/AuthButton";
import TitleText from "../../../customComponents/Text/TitleText";
import { useAuth } from "../../../context/AuthContext";
import { authLogin, authEmail } from "../../../api_url/authFacility";
import { getFacilityByEmail } from "../../../api_url/facilityTable";
import { Ionicons } from "@expo/vector-icons";

const Login = ({ setContainerScreen, state, setState, props }) => {
  const { width, height } = Dimensions.get("window");
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [emailFinish, setEmailFinish] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(true);

  useEffect(() => {
    if (Platform.OS !== "web") {
      const backAction = () => {
        Alert.alert("Hold on!", "Are you sure you want to go back?", [
          {
            text: "Cancel",
            onPress: () => null,
            style: "cancel",
          },
          { text: "YES", onPress: () => BackHandler.exitApp() },
        ]);
        return true;
      };

      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        backAction
      );

      return () => backHandler.remove();
    }
  }, []);

  const { updateAuthToken } = useAuth();

  // Login
  const logIn = async (email, password) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setState({
      ...state,
      password: password
    })
    if (user?.password !== password) {
      setLoading(false);
      setError("Password not matched!");
    } else {
      try {
        await authLogin({ email: email, password: password })
          .then(async resp => {
            await getFacilityByEmail(resp?.authToken, email).then(async (res) => {
              await updateAuthToken(resp?.authToken, res?.id?.toString(), res?.uniqueId?.toString());
              setError(undefined);
              setLoading(false);
            }).catch(e => {
              setError(e?.message);
              setLoading(false);
            });
          }).catch(e => {
            setError(e?.message);
            setLoading(false);
          });
      } catch (e) {
        setError(e.message || JSON.stringify(e));
        setLoading(false);
      }
    }
  };

  const continueLogin = async (email) => {
    if (loadingEmail) {
      return;
    }
    setLoadingEmail(true);
    setState({
      ...state,
      email: email
    })
    try {
      await authEmail(email)
        .then(async resp => {
          setUser(resp);
          setError("");
          setEmailFinish(true)
          setLoadingEmail(false);
        }).catch(e => {
          setError(e?.message);
          setLoadingEmail(false);
        });
    } catch (e) {
      setError(e.message || JSON.stringify(e));
      setLoadingEmail(false);
    }
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : ""}
    >
      <View>
        <View style={styles.content}>
          <Text style={styles.title}>Welcome to NURSD</Text>
          <TitleText text={"Sign In"} />
        </View>
        <View style={{
          flex: Platform.OS === "web" ?
            {
              flex: 1, width: width - 20
            } :
            { flex: 1 }
        }}>
          <View style={styles.inputContainer}>
            <TextInput
              onChangeText={(text) => setEmail(text)}
              value={email}
              keyboardType={"email-address"}
              autoCapitalize="none"
              placeholderTextColor="#b3b3b3"
              placeholder={"Email"}
              style={styles.input}
              editable={!emailFinish}
              returnKeyType="next"
              onSubmitEditing={() => continueLogin(email)}
            />
            {email && !emailFinish ? (
              <TouchableOpacity onPress={() => setEmail("")}>
                <Ionicons name="close-sharp" size={20} color="#808080" />
              </TouchableOpacity>
            ) : null}
            {email && emailFinish ? (
              <TouchableOpacity onPress={() => setEmailFinish(false)}>
                <Ionicons name="pencil" size={16} color="#808080" />
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
        {(!emailFinish) &&
          <View style={styles.buttonContainer}>
            <AuthButton
              name={loadingEmail ? "Loading..." : "Continue"}
              onPress={() => email === "" ? null : continueLogin(email)}
              color={email === "" ? "#ccc" : "#00b359"}
            />
          </View>
        }
        <View style={styles.termsContainer}>
          <Text style={styles.termsText}>
            By logging in, you agree to our{" "}
            <Text 
              style={styles.linkText}
              onPress={() => Linking.openURL('https://nursdinc.com/terms')}
            >
              Terms
            </Text>
            {" "}and acknowledge that you have read our{" "}
            <Text 
              style={styles.linkText}
              onPress={() => Linking.openURL('https://nursdinc.com/privacy-policy')}
            >
              Privacy Policy
            </Text>
            .
          </Text>
        </View>
        {emailFinish &&
          <View style={{ ...styles.inputContainer, marginTop: 15 }}>
            <TextInput
              onChangeText={(text) => setPassword(text)}
              value={password}
              keyboardType={"default"}
              autoCapitalize="none"
              placeholderTextColor="#b3b3b3"
              placeholder={"Password"}
              style={styles.input}
              secureTextEntry={passwordVisible}
              autoFocus={true}
              returnKeyType="go"
              onSubmitEditing={() => logIn(email, password)}
            />
            <TouchableOpacity
              onPress={() => setPasswordVisible(!passwordVisible)}
            >
              <Ionicons
                size={20}
                color="#808080"
                name={passwordVisible ? "eye" : "eye-off"}
              />
            </TouchableOpacity>
            {password ? (
              <TouchableOpacity onPress={() => setPassword("")}>
                <Ionicons name="close-sharp" size={20} color="#808080" />
              </TouchableOpacity>
            ) : null}
          </View>
        }
      </View>
      <TouchableOpacity
        onPress={() => setContainerScreen("ForgotPassword")}
        style={styles.forgotPassword}
      >
        <Text style={styles.forgotPasswordText}>Forgot Password ?</Text>
      </TouchableOpacity>
      {error !== "" && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
          {error === "User is not confirmed!" && (
            <TouchableOpacity
              onPress={() => setContainerScreen("ConfirmCode")}
            >
              <Text style={styles.verifyText}>Click here to verify.</Text>
            </TouchableOpacity>
          )}
        </View>
      )}
      {emailFinish &&
        <View style={styles.buttonContainer}>
          <AuthButton
            name={loading ? "Loading..." : "Login"}
            onPress={() => (email === "" || password === "") ? null : logIn(email, password)}
            color={(email === "" || password === "") ? "#ccc" : "#00b359"}
          />
        </View>
      }
    </KeyboardAvoidingView >
  );
};

export default Login;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
  content: {
    alignItems: "center",
  },
  title: {
    color: "#00b359",
    fontWeight: "bold",
    fontSize: 20,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginHorizontal: 20,
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    elevation: 1,
    borderWidth: 0.5,
    borderColor: "#e6e6e6",
    paddingRight: 10,
    justifyContent: "space-between",
  },
  input: {
    flex: 1,
    height: 40,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 5,
    fontSize: 12,
    color: "#737373",
  },
  forgotPassword: {
    marginTop: 10,
    alignItems: "flex-end",
    marginRight: 20,
  },
  forgotPasswordText: {
    fontSize: 14,
    color: "#000",
    // textDecorationLine: "underline",
    fontWeight: "600"
  },
  errorContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    color: "red",
    textAlign: "center",
    marginVertical: 10,
  },
  verifyText: {
    color: "#000",
    textDecorationLine: "underline",
    marginLeft: 5,
  },
  buttonContainer: {
    alignItems: "center",
    marginTop: 10,
  },
  termsContainer: {
    marginTop: 15,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  termsText: {
    fontSize: 12,
    color: '#737373',
    textAlign: 'center',
  },
  linkText: {
    fontSize: 12,
    color: '#00b359',
    textDecorationLine: 'underline',
  },
});